import { render, staticRenderFns } from "./FormGraphicalPDFTextSubfield.vue?vue&type=template&id=617134a8&scoped=true&"
import script from "./FormGraphicalPDFTextSubfield.vue?vue&type=script&lang=ts&"
export * from "./FormGraphicalPDFTextSubfield.vue?vue&type=script&lang=ts&"
import style0 from "./FormGraphicalPDFTextSubfield.vue?vue&type=style&index=0&id=617134a8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "617134a8",
  null
  
)

export default component.exports
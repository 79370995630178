
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { PDFFormFieldPageDef, PDFFormField, GraphicalPDFSignatureField } from 'client-website-ts-library/types/Forms/FieldTypes';
import { PDFFormSubfieldChangedData } from '@/app_code/Forms';
import { FormSubmissionValidationError } from 'client-website-ts-library/types/Forms';
import { Context } from 'client-website-ts-library/types';
import { API } from 'client-website-ts-library/services';

import FormGraphicalPDFSignatureField from './FormGraphicalPDFSignatureField.vue';
import FormGraphicalPDFSubfield from './FormGraphicalPDFSubfield.vue';

@Component({
  components: {
    FormGraphicalPDFSignatureField,
    FormGraphicalPDFSubfield,
  },
})
export default class FormGraphicalPDFFieldPage extends Vue {
  @Prop()
  private readonly formType!: string;

  @Prop()
  private readonly formContext!: Context;

  @Prop()
  private readonly fieldKey!: string;

  @Prop()
  private readonly def!: PDFFormFieldPageDef;

  @Prop({ default: () => [] })
  private readonly errors!: FormSubmissionValidationError[];

  @Prop()
  private readonly sigFields!: GraphicalPDFSignatureField[];

  mounted() {
    const img = this.$refs.image as HTMLImageElement;

    API.Forms.GetPDFFieldPage(this.formType, this.formContext, this.fieldKey, this.def.Number).then((imageData) => {
      imageData.blob().then((blobData) => {
        img.src = URL.createObjectURL(blobData);
      });
    });
  }

  handleSubfieldChanged(data: PDFFormSubfieldChangedData) {
    this.$emit('change', data);
  }
}
